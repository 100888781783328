@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #622878;
  --accent: #61287733;
  --light: #f7f6f2;
  --transparent-purple: hsla(48, 24%, 96%, 0.05);
}

html {
  scroll-behavior: smooth;
}

h1 {
  line-height: 1.2 !important;
}

.nav-shadow {
  box-shadow: 0 10px 15px 2px rgba(0, 0, 0, 0.05);
}

.rounded-4xl {
  border-radius: 2rem;
}

.rounded-5xl {
  border-radius: 3rem;
}

.container-full {
  max-width: calc(100vw - 3rem);
  margin-inline: auto;
  /* padding-inline: 1.5rem; */
}

@font-face {
  font-family: "Inter";
  src: url("../src/fonts/Inter-VariableFont_slnt\,wght.ttf");
}

.container {
  @apply mx-auto px-6 max-w-7xl;
}

.container-bp {
  padding-inline: 3rem;
}

.myworld-container {
  display: grid;
  /* grid-template-columns: auto 400px; */
}

@media (min-width: 1024px) {
  .myworld-container {
    display: grid;
    grid-template-columns: auto 400px;
  }
}

@media (min-width: 768px) {
  .container-full {
    max-width: calc(100vw - 6rem);
    margin-inline: auto;
    /* padding-inline: 1.5rem; */
  }

  .rounded-5xl {
    border-radius: 4rem;
  }
}
